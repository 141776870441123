import { StoreContext } from '../store';
import {
	Container,
	StackDivider,
	Text,
	VStack,
	useColorMode,
	useColorModeValue
} from '@chakra-ui/react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useRef } from 'react';
import Chatbox from './ChatBox';
import Message from './Message';
import { useTranslate } from '@tolgee/react';
import MessageContainer from './MessageContainer';



export default function AppContainer() {
	const { t } = useTranslate();
	const {
		state: {
			status: { page }
		}
	} = useContext(StoreContext);
	const messages = page.content.right_upper_panel.message_list;
	const color = useColorModeValue('gray.300', 'gray.700');

	return (
		<>
			{messages.length === 0 ? (
				<VStack
					h="100%"
					maxW="100vw"
					maxH="100vh"
					justifyContent="space-between"
					spacing={24}
					paddingTop={24}
					paddingBottom={3}
				>
					<Container
						minW="100%"
						h="100%"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<VStack justifyContent="center" mt="8">
							<FontAwesomeIcon
								icon={page.content.left_panel.title_left_panel.emoticon as IconName}
								size="2xl"
							/>
							<Text fontWeight="semibold" fontSize="xl">
								{page.content.right_upper_panel.title_right_panel.text &&
									t(page.content.right_upper_panel.title_right_panel.text)}
							</Text>
						</VStack>
					</Container>
					{!page.content.right_lower_panel.hidden && <Chatbox />}
				</VStack>
			) : (
				<VStack
					maxW="100vw"
					maxH="100vh"
					justifyContent="space-between"
					spacing={24}
					paddingTop={8}
					paddingBottom={3}
				>
					<MessageContainer/>
					{!page.content.right_lower_panel.hidden && <Chatbox />}
				</VStack>
			)}
		</>
	);
}
