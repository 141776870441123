import { StoreContext } from "../store";
import { Popover, PopoverTrigger, Button, Portal, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton, PopoverBody, PopoverFooter, HStack, Text, VStack, Select, Textarea, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";

export default function ModelSwitcher() {
    const { state: { models, currentChat }, state, setModel, setState, saveChat, chatSaving } = useContext(StoreContext);
    const [prompt, setPrompt] = useState('');
    
    useEffect(() => {
        if (currentChat && currentChat.messages && currentChat.messages[0].content) {
            setPrompt(currentChat.messages[0].content);
        }
    }, [currentChat]);

    useEffect(() => {
        if (currentChat && currentChat.messages) currentChat.messages[0].content = prompt;
    }, [prompt]);

    return (
        <Popover placement="bottom-start">
            {({ onClose }) => (
                <>
                    <PopoverTrigger>
                        <Button variant="outline" fontWeight="normal">
                            <HStack>
                                <Text fontWeight="normal">{currentChat && models.find(m => m.id === currentChat.model)?.name || 'Select model'}</Text>
                                <FontAwesomeIcon icon="sliders" />
                            </HStack>
                        </Button>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                                <VStack>
                                    <VStack w="100%">
                                        <Text w="100%" fontSize="sm">Model</Text>
                                        <Select
                                            size="sm"
                                            cursor="pointer"
                                            value={currentChat?.model}
                                            onChange={e => {
                                                setModel(parseInt(e.target.value));
                                            }}
                                        >
                                            {
                                                models.map((model, idx) => (
                                                    <option key={idx} value={model.id}>{model.name}</option>
                                                ))
                                            }
                                        </Select>
                                    </VStack>

                                    <VStack w="100%">
                                        <Text w="100%" fontSize="sm">Prompt</Text>
                                        <Textarea
                                            size="sm"
                                            value={prompt}
                                            onChange={e => setPrompt(e.target.value)}
                                            placeholder="You're a helpful chat assistant"
                                        />
                                    </VStack>

                                    <Accordion w="100%" allowToggle={true}>
                                        <AccordionItem border='none'>
                                            <AccordionButton px={0} py={2}>
                                                <Box as='span' flex='1' textAlign='left' fontSize='sm'>
                                                    Advanced settings
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                            <AccordionPanel pb={4} px={0} as={VStack} spacing={2} w="100%">
                                                <VStack w="100%">
                                                    <Text w="100%" fontSize="xs">Temperature: <b>{currentChat?.temperature}</b></Text>

                                                    <Slider
                                                        aria-label='slider-ex-4'
                                                        defaultValue={currentChat?.temperature}
                                                        min={0}
                                                        max={2}
                                                        step={0.05}
                                                        size="sm"
                                                        onChange={e => {
                                                            if (currentChat) {
                                                                currentChat.temperature = e;
                                                                setState({ ...state, currentChat });
                                                            }
                                                        }}
                                                    >
                                                        <SliderTrack bg='red.100'>
                                                            <SliderFilledTrack bg='tomato' />
                                                        </SliderTrack>
                                                        <SliderThumb boxSize={2}>
                                                            <Box color='tomato' />
                                                        </SliderThumb>
                                                    </Slider>
                                                </VStack>
                                                <VStack w="100%">
                                                    <Text w="100%" fontSize="xs">Top P: <b>{currentChat?.top_p}</b></Text>

                                                    <Slider
                                                        aria-label='slider-ex-4'
                                                        defaultValue={currentChat?.top_p}
                                                        min={0}
                                                        max={1}
                                                        step={0.01}
                                                        size="sm"
                                                        onChange={e => {
                                                            if (currentChat) {
                                                                currentChat.top_p = e;
                                                                setState({ ...state, currentChat });
                                                            }
                                                        }}
                                                    >
                                                        <SliderTrack bg='red.100'>
                                                            <SliderFilledTrack bg='tomato' />
                                                        </SliderTrack>
                                                        <SliderThumb boxSize={2}>
                                                            <Box color='tomato' />
                                                        </SliderThumb>
                                                    </Slider>
                                                </VStack>
                                                <VStack w="100%">
                                                    <Text w="100%" fontSize="xs">Context Length: <b>{currentChat?.context_length}</b></Text>

                                                    <Slider
                                                        aria-label='slider-ex-4'
                                                        defaultValue={currentChat?.context_length}
                                                        min={0}
                                                        max={models.find(m => m.id === currentChat?.model)?.context_length || 4096}
                                                        step={1}
                                                        size="sm"
                                                        onChange={e => {
                                                            if (currentChat) {
                                                                currentChat.context_length = e;
                                                                setState({ ...state, currentChat });
                                                            }
                                                        }}
                                                    >
                                                        <SliderTrack bg='red.100'>
                                                            <SliderFilledTrack bg='tomato' />
                                                        </SliderTrack>
                                                        <SliderThumb boxSize={2}>
                                                            <Box color='tomato' />
                                                        </SliderThumb>
                                                    </Slider>
                                                </VStack>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </VStack>
                            </PopoverBody>
                            <PopoverFooter
                                display='flex'
                                alignItems='center'
                                justifyContent='end'
                            >
                                <Button
                                    variant="outline"
                                    fontWeight="normal"
                                    size="sm"
                                    isLoading={chatSaving}
                                    onClick={async () => {
                                        if (currentChat?.id) {
                                            await saveChat();
                                        }
                                        onClose();
                                    }}
                                >
                                    Save
                                </Button>
                            </PopoverFooter>
                        </PopoverContent>
                    </Portal>
                </>
            )}
        </Popover>
    );
}