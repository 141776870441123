import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import StoreProvider from './store';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Apps from './pages/Apps';
import App from './pages/App';
import Loading from './pages/Loading';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import theme from './theme';
import enTranslation from './i18n/en.json';
import frTranslation from './i18n/fr.json';
import nlTranslation from './i18n/nl.json';
import { AuthProvider } from "react-oidc-context";
import AuthCallback from './pages/AuthCallback';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
	{
		path: '/',
		element: <Apps />
	},
	{
		path: '/loading',
		element: <Loading />
	},
	{
		path: '/auth/login/callback',
		element: <AuthCallback />
	},
	{
		path: '/:appName',
		element: <App />
	},
]);

//get browser language
const browserLanguage = navigator.language
	? navigator.language.split('-')[0]
	: 'en';

const tolgee = Tolgee().use(DevTools()).use(FormatSimple()).init({
	language: browserLanguage,
	staticData: {
		en: enTranslation,
		fr: frTranslation,
		nl: nlTranslation
	}
});

const oidcConfig = {
	authority: process.env.REACT_APP_AUTH_HOST + '/realms/' + process.env.REACT_APP_AUTH_REALM,
	client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
	client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
	redirect_uri: window.location.protocol + '//' + window.location.host + '/auth/login/callback',
};

root.render(
	<TolgeeProvider tolgee={tolgee}
		fallback="Loading...">
		<ChakraProvider theme={theme}>
			<AuthProvider {...oidcConfig}>
				<StoreProvider>
					<RouterProvider router={router} />
				</StoreProvider>
			</AuthProvider>
		</ChakraProvider>
	</TolgeeProvider>
);
