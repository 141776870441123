import { Container, Spinner } from '@chakra-ui/react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

export default function AuthCallback() {
	const auth = useAuth();
	const navigate = useNavigate();

	if (auth.isLoading) {
		return (
			<Container
				maxW="100%"
				minH="100%"
				justifyContent="center"
				alignItems="center"
				display="flex"
			>
				<Spinner size="xl" />
			</Container>
		);
	} else if (auth.isAuthenticated) {
		navigate('/');
	} else {
		auth.signinRedirect();
	}

	return <></>;
}
