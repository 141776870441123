import { Log } from "@/types/log";
import ReactMarkdown from 'react-markdown';
import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, HStack, Spinner, Text, VStack, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

interface Props {
    logs: Log[];
}

export default function Logs({ logs }: Props) {
    const groupedBySteps = logs.reduce((acc, log) => {
        if (!acc[log.step]) acc[log.step] = [];
        acc[log.step].push(log);
        return acc;
    }, {} as Record<string, Log[]>);

    const textColor = useColorModeValue('black', 'white');
    const finishTextColor = useColorModeValue('gray.500', 'gray.300');
    const hoverColor = useColorModeValue('gray.200', 'gray.700');

    return (
        <Accordion w="100%" allowToggle={true}>
            {
                Object.entries(groupedBySteps).map(([step, logs]) => (
                    <AccordionItem key={step} border="none">
                        <h2>
                            <AccordionButton color={logs.filter(log => log.finished).length === 0 ? textColor : finishTextColor}>
                                <HStack>
                                    <AccordionIcon />
                                    {logs.filter(log => log.finished).length === 0 ? <Spinner width={3} height={3} /> : <CheckCircleIcon width={3} height={3} />}
                                    <Text fontWeight="medium">{step}</Text>
                                </HStack>
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <VStack spacing={0} overflow="hidden">
                                {
                                    logs.map((log, index) => (
                                        <HStack key={index} p={1} fontFamily="monospace" fontSize="xs" _hover={{ bg: hoverColor }} w="100%">
                                            <Text pl={6} pr={2} color={finishTextColor}>{index + 1}</Text>
                                            <Text>
                                                <ReactMarkdown
                                                    children={log.message}
                                                    skipHtml
                                                />
                                            </Text>
                                        </HStack>
                                    ))
                                }
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                ))
            }
        </Accordion>
    );
}